#app-container #app-main {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: verdana;
  font-size: 11px;
  border-top: 1px solid white;
}

/* Builds output styling */
.usage th {
  cursor: pointer;
}
.lineup div {
  text-align: left;
}

.lineup header {
  margin: 10px;
}

.lineup header button {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.lineup footer {
  margin-top: 40px;
  border-top: 1px solid #ccc;
  text-align: left;
}

.lineup button {
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #EAEAEA;
  font-size: 1.0em;
  font-weight: normal;
  margin: 0px;
}

table.lineup {
  display: inline-block;
  border-collapse: collapse;
  box-shadow: 2px 2px 5px #ccc;
  margin: 10px;
  font-size: 1.0em;
}

.lineup table.usage {
  float: left;
}

.lineup table.usage caption {
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #EAEAEA;
  background-color: #F5F5F5;
}

.lineup tbody tr:nth-child(odd) {
  background-color: #F5F5F5;
}

.lineup tbody tr:nth-child(even) {
  background-color: #FFF;
}

.lineup thead tr {
  background-color: #fff !important;
  text-transform: uppercase;
}

.lineup th,
.lineup td {
  border: 1px solid #EAEAEA;
  padding: 5px;
  text-align: left;
}

.lineup td:first-child,
.lineup th:first-child {
  padding-left: 10px;
}

.lineup th {
  font-size: 0.6em;
  font-weight: bold;
}

.lineup td.right,
.lineup th.right {
  text-align: right;
  padding-right: 10px;
}

.lineup td.center,
.lineup th.center {
  text-align: center;
}

.playerName { 
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 99%; 
  white-space: nowrap;
}

.pick { 
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 99%;
  white-space: nowrap;
}

.rotate_text {
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  margin: 0 auto;
}
